.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s; }

@keyframes flip {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in; }
  to {
    transform: perspective(400px);
    animation-timing-function: ease-in; } }

.animated.flip {
  backface-visibility: visible;
  animation-name: flip; }

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  animation-name: bounceInUp; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  animation-name: flash; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.display-table {
  width: 100%;
  height: 100%;
  display: table;
  text-align: center; }

.table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 20px; }

#outerWidgetContainer {
  margin: 0 !important; }

@font-face {
  font-family: 'Graphik';
  src: url("../fonts/Graphik-Regular-Web.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Regular-Web.woff") format("woff"), url("../fonts/Graphik-Regular-Web.ttf") format("truetype"), url("../fonts/Graphik-Regular-Web.svg#svgFontName") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Graphik';
  src: url("../fonts/Graphik-RegularItalic-Web.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Graphik-RegularItalic-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-RegularItalic-Web.woff") format("woff"), url("../fonts/Graphik-RegularItalic-Web.ttf") format("truetype"), url("../fonts/Graphik-RegularItalic-Web.svg#svgFontName") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Graphik';
  src: url("../fonts/Graphik-Semibold-Web.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Graphik-Semibold-Web.eot?#iefix") format("embedded-opentype"), url("../fonts/Graphik-Semibold-Web.woff") format("woff"), url("../fonts/Graphik-Semibold-Web.ttf") format("truetype"), url("../fonts/Graphik-Semibold-Web.svg#svgFontName") format("svg");
  /* Legacy iOS */
  font-weight: 600;
  font-style: normal; }

body {
  font-family: "Graphik", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0rem;
  color: #000;
  text-transform: none;
  background-color: #fff; }

a {
  font-family: "Graphik", sans-serif;
  font-size: 18px;
  letter-spacing: 0rem;
  color: #0042FC;
  text-transform: none;
  text-decoration: none; }

blockquote {
  font-family: "Optima W01", sans-serif;
  font-size: 46px;
  line-height: 56px;
  letter-spacing: 0rem;
  color: #000;
  text-transform: none; }

.page-header {
  font-family: "Optima W01", sans-serif;
  font-weight: normal;
  letter-spacing: 0rem;
  color: #0042FC;
  text-transform: capitalize;
  font-size: 38px;
  line-height: 44px;
  margin-bottom: 42px; }
  @media only screen and (min-width: 748px) {
    .page-header {
      font-size: 78px; }
      .page-header.search {
        font-size: 42px; } }

.module-header {
  font-family: "Graphik", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: .1rem;
  text-transform: uppercase; }

.page-filter {
  font-family: "Optima W01", sans-serif;
  font-size: 42px;
  letter-spacing: 0rem;
  color: #0042FC;
  text-transform: uppercase; }

.text-center {
  text-align: center; }

.tags a, .post-categories, .post-categories a {
  font-family: "Graphik", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .1rem;
  color: #0042FC;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased; }

.credits, .credits a, .image-caption {
  font-family: "Graphik", sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  letter-spacing: .1rem;
  text-transform: capitalize; }

.contact-email {
  font-family: "Optima W01", sans-serif;
  letter-spacing: 0;
  color: #0042FC;
  text-transform: lowercase;
  font-size: 24px;
  display: block; }
  @media only screen and (min-width: 748px) {
    .contact-email {
      font-size: 42px; } }

.page-copy {
  display: block;
  padding: 40px 20px 40px;
  margin: 0 auto; }
  @media only screen and (min-width: 748px) {
    .page-copy {
      padding: 40px 30px 40px;
      max-width: 700px; } }
  .page-copy:first-child {
    padding-top: 0; }

[data-ravelin-text] .ravelin-text {
  transition: color .2s ease-in; }

[data-ravelin-text].dark .backdrop {
  background-color: rgba(255, 255, 255, 0.1); }

[data-ravelin-text].dark .ravelin-text {
  color: #000 !important; }

[data-ravelin-text].light .backdrop {
  background-color: rgba(0, 0, 0, 0.1); }

[data-ravelin-text].light .ravelin-text {
  color: #fff !important; }

::selection {
  opacity: 1;
  background: #0042FC;
  color: #fff; }
  footer ::selection {
    background: #fff;
    color: #0042FC; }

::-moz-selection {
  opacity: 1;
  background: #0042FC;
  color: #fff; }
  footer ::-moz-selection {
    background: #fff;
    color: #0042FC; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  padding: 58px 0 0;
  margin: 0; }
  @media only screen and (min-width: 748px) {
    body {
      padding: 50px; } }

.content {
  min-height: 82vh;
  max-width: 1600px;
  margin: 0 auto; }

.sticky-article-title {
  transition: opacity .2s ease-in-out;
  font-family: "Optima W01", sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #000;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 115px;
  padding-top: 70px;
  padding-left: 90px;
  padding-right: 90px;
  margin: 0;
  opacity: 0;
  z-index: 1;
  max-width: 100% !important; }
  .sticky-article-title.visible {
    opacity: 1; }
  .sticky-article-title .sticky-title {
    display: inline-block;
    vertical-align: top; }
  @media only screen and (max-width: 748px) {
    .sticky-article-title {
      display: none; } }
  .sticky-article-title .sticky-share {
    position: absolute;
    right: 90px;
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: .1em;
    font-weight: 600; }
    .sticky-article-title .sticky-share p {
      padding: 0;
      margin: 0; }
    .sticky-article-title .sticky-share ul {
      padding-left: 10px;
      display: inline-block; }
    .sticky-article-title .sticky-share li {
      margin: 0 5px; }
      .sticky-article-title .sticky-share li a {
        padding-top: 6px;
        font-size: 14px;
        color: #000;
        transition: color .2s ease-in; }
        .sticky-article-title .sticky-share li a:hover {
          color: #0042FC; }
      .sticky-article-title .sticky-share li:first-child {
        margin-left: 0; }
      .sticky-article-title .sticky-share li:last-child {
        margin-right: 0; }
    .sticky-article-title .sticky-share span {
      display: none !important; }

.article-row {
  padding: 20px; }
  @media only screen and (min-width: 748px) {
    .article-row {
      padding: 90px 30px 0; }
      .article-row:first-child {
        padding-top: 25px; }
      .article-row.text-row + .text-row {
        padding: 40px 30px 0; }
      .article-row.article-foot {
        padding-top: 45px; } }
  .article-row .callout a, .article-row .text-block a {
    font-size: inherit;
    line-height: inherit;
    display: inline;
    border-bottom: 1px solid #0042FC;
    line-height: 1;
    transition: color .2s ease-in-out, border-color .2s ease-in-out; }
    .article-row .callout a:hover, .article-row .text-block a:hover {
      color: black;
      border-color: black; }
  .article-row .callout:first-child, .article-row .text-block:first-child {
    margin-top: 0; }
  .article-row .callout:last-child, .article-row .text-block:last-child {
    margin-bottom: 0; }

.grid-items {
  display: none; }

.image-grid {
  transition: opacity .35s ease-in; }
  .image-grid .item {
    width: 100%;
    float: left;
    padding: 15px;
    display: none; }
    .image-grid .item.loaded {
      display: block; }
      .image-grid .item.loaded img {
        display: block; }
    @media only screen and (min-width: 748px) {
      .image-grid .item {
        width: 50%; } }
    .image-grid .item img {
      display: none;
      width: 100%; }

.text-block {
  width: 50%;
  margin-left: 25.5%; }
  @media only screen and (max-width: 1200px) {
    .text-block {
      width: 62.5%; } }
  @media only screen and (max-width: 747px) {
    .text-block {
      width: 100%;
      margin-left: 0; } }
  .text-block img {
    max-width: 100%; }
  .text-block p {
    margin-top: 0;
    margin-bottom: 1.4em; }
    .text-block p:first-child {
      margin-top: 0; }
    .text-block p:last-child {
      margin-bottom: 0; }

.double-image-block {
  font-size: 0;
  padding: 20px 5px 0;
  margin-bottom: -10px; }
  @media only screen and (min-width: 748px) {
    .double-image-block {
      padding: 100px 15px 0; } }
  .double-image-block .double-image {
    display: inline-block;
    width: 50%;
    padding: 0 15px; }
    .double-image-block .double-image img {
      max-width: 100%; }

.block-quote {
  font-family: "Optima W01", sans-serif;
  font-size: 46px;
  letter-spacing: 0;
  color: #000;
  text-transform: none; }
  .block-quote blockquote {
    padding: 0;
    margin: 0; }
    .block-quote blockquote:before, .block-quote blockquote:after {
      position: relative;
      content: '"';
      font-style: italic; }
    .block-quote blockquote:before {
      transform: translateX(-100%); }
    .block-quote blockquote:after {
      position: absolute; }
    @media only screen and (max-width: 748px) {
      .block-quote blockquote {
        margin: 0px; } }

.image-captions-block {
  overflow: hidden;
  margin-top: 50px; }
  @media only screen and (min-width: 748px) {
    .image-captions-block {
      margin-top: 90px; } }
  .image-captions-block .item {
    position: relative;
    margin-top: 30px; }
    .image-captions-block .item:first-child {
      margin-top: 0; }
    .image-captions-block .item img {
      display: inline-block;
      vertical-align: bottom;
      margin-right: -.25em;
      width: 100%;
      padding: 0 0px 0 20px; }
      @media only screen and (min-width: 748px) {
        .image-captions-block .item img {
          width: 87.5%; } }
    .image-captions-block .item .image-caption {
      display: inline-block;
      padding: 0 20px;
      margin-right: -.25em;
      vertical-align: bottom; }
      @media only screen and (min-width: 748px) {
        .image-captions-block .item .image-caption {
          width: 12.5%;
          padding: 0 20px 0 10px; }
          .image-captions-block .item .image-caption p {
            margin: 0; } }
    .image-captions-block .item.odd {
      text-align: right; }
      .image-captions-block .item.odd img {
        padding: 0 20px 0 0px; }
      .image-captions-block .item.odd .image-caption {
        padding: 0 10px 0 20px; }

.image-block img {
  width: 100%; }

@media only screen and (min-width: 748px) {
  .image-block + .image-caption {
    max-width: 50%; } }

.playlist-block {
  width: auto;
  margin-top: 40px; }
  .playlist-block iframe {
    width: 100%; }
  @media only screen and (max-width: 748px) {
    .playlist-block {
      margin-top: 50px;
      padding: 0 20px; } }

.video-block {
  overflow: hidden;
  position: relative;
  padding: 50px 0;
  margin: 50px 0; }
  @media only screen and (min-width: 748px) {
    .video-block {
      padding: 50px 0 0; } }
  .video-block .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
    top: 50%; }
  .video-block iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-block video {
    min-width: 50%;
    display: block;
    margin: 0 auto;
    width: auto;
    padding: 0 20px; }
    .video-block video.full-width {
      width: 100%;
      height: auto;
      padding-bottom: 0; }
  .video-block #backdrop {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(20px);
    transform: scale(2);
    z-index: 0;
    backface-visibility: hidden; }

.video-playing.video-visible .video-block {
  overflow: visible;
  width: 100vw;
  background-color: #000; }
  @media only screen and (min-width: 748px) {
    .video-playing.video-visible .video-block {
      left: -50px; } }
  .video-playing.video-visible .video-block #backdrop {
    opacity: 1; }

.article-preview {
  border: none;
  outline: none;
  position: relative; }
  .article-preview p {
    max-width: none; }
  .article-preview .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .article-preview .overlay-wrapper {
    position: relative;
    display: block;
    font-size: 0;
    width: 100%; }
  .article-preview .title {
    font-family: "Optima W01", sans-serif; }
  .article-preview img {
    border: none;
    outline: none;
    max-width: 100%;
    display: block; }
  .article-preview .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .article-preview:hover img {
    filter: sepia(1) hue-rotate(185deg); }
  .article-preview:hover .overlay-wrapper {
    position: relative;
    display: block; }
  .article-preview:hover .overlay {
    background-color: rgba(0, 66, 252, 0.3); }
  .article-preview img {
    width: 100%;
    max-width: 100%; }
    .home .article-preview img {
      width: auto; }
  .article-preview .post-categories {
    color: #fff;
    font-family: "Graphik", sans-serif;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .100rem;
    text-transform: uppercase;
    margin: 20px 0 6px; }
    @media only screen and (min-width: 748px) {
      .article-preview .post-categories {
        font-size: 14px;
        line-height: 18px; } }
  .article-preview .subtitle {
    font-family: "Optima W01", sans-serif;
    font-size: 42px;
    line-height: 46px;
    font-weight: normal;
    letter-spacing: 0;
    color: #0042FC; }
  .article-preview.hero {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 92vh; }
    .article-preview.hero.square {
      background-size: auto 100%; }
    .article-preview.hero .post-categories {
      margin-bottom: 14px;
      font-size: 12px;
      line-height: 16px; }
      .article-preview.hero .post-categories a {
        color: #fff; }
      @media only screen and (min-width: 1024px) {
        .article-preview.hero .post-categories a {
          color: #fff;
          font-size: 18px; } }
    .article-preview.hero .smooth-scroll {
      position: absolute;
      font-size: 3rem;
      bottom: 55px;
      left: 50%;
      transform: translateX(-50%);
      color: #fff; }
    .article-preview.hero[data-is-dark="true"] a, .article-preview.hero[data-is-dark="true"] p, .article-preview.hero[data-is-dark="true"] i:before, .article-preview.hero .is-dark a, .article-preview.hero .is-dark p, .article-preview.hero .is-dark i:before {
      color: #000; }
    .article-preview.hero[data-is-dark="false"], .article-preview.hero .is-light {
      background-color: rbga(#000, 0.1); }
      .article-preview.hero[data-is-dark="false"] a, .article-preview.hero[data-is-dark="false"] p, .article-preview.hero[data-is-dark="false"] i:before, .article-preview.hero .is-light a, .article-preview.hero .is-light p, .article-preview.hero .is-light i:before {
        color: #fff; }
    .article-preview.hero.playlist {
      position: relative;
      padding: 10px; }
      .article-preview.hero.playlist .playlist-hero-image, .article-preview.hero.playlist .playlist-wrapper {
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover; }
        @media only screen and (min-width: 748px) {
          .article-preview.hero.playlist .playlist-hero-image, .article-preview.hero.playlist .playlist-wrapper {
            right: 50%;
            margin-right: 10px; } }
        .article-preview.hero.playlist .playlist-hero-image a, .article-preview.hero.playlist .playlist-hero-image p, .article-preview.hero.playlist .playlist-wrapper a, .article-preview.hero.playlist .playlist-wrapper p {
          color: #0042FC; }
        .article-preview.hero.playlist .playlist-hero-image .icon-tape-box:before, .article-preview.hero.playlist .playlist-wrapper .icon-tape-box:before {
          color: #fff;
          font-size: 35px; }
      @media only screen and (min-width: 748px) {
        .article-preview.hero.playlist .playlist-hero-image {
          position: fixed;
          top: 60px;
          left: 60px;
          bottom: 60px; } }
      .article-preview.hero.playlist .playlist-wrapper {
        top: 10px;
        right: 0;
        left: 0;
        bottom: 30px;
        border: 6px solid blue; }
        @media only screen and (min-width: 748px) {
          .article-preview.hero.playlist .playlist-wrapper {
            left: 50%; } }
    @media only screen and (min-width: 748px) {
      .article-preview.hero {
        margin: 0 auto; } }
  .article-preview.uncropped {
    text-align: center;
    padding: 1em 20px 5px;
    background-size: 0 0; }
    @media only screen and (min-width: 748px) {
      .article-preview.uncropped {
        padding: 50px 30px 5px; } }
    .article-preview.uncropped:hover {
      cursor: pointer; }
      .article-preview.uncropped:hover img {
        filter: none; }
    .article-preview.uncropped .post-categories a {
      color: black; }
    .article-preview.uncropped .uncropped-titles {
      max-width: 760px;
      margin: 0 auto 2em; }
      @media only screen and (min-width: 748px) {
        .article-preview.uncropped .uncropped-titles {
          margin-bottom: 42px; } }
      .article-preview.uncropped .uncropped-titles .post-categories {
        margin-top: 0;
        margin-bottom: 12px; }
    .article-preview.uncropped .title {
      margin: 0 0 30px;
      color: black;
      font-size: 38px;
      line-height: 38px; }
      @media only screen and (min-width: 748px) {
        .article-preview.uncropped .title {
          font-size: 38px;
          line-height: 38px;
          margin: 0 0 1.2rem 0; } }
      @media only screen and (min-width: 1024px) {
        .article-preview.uncropped .title {
          font-size: 78px;
          line-height: 78px; } }
    .article-preview.uncropped .lead-in {
      max-width: 100%;
      font-family: "Graphik", sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      color: black;
      text-transform: none;
      margin: 0 auto; }
      @media only screen and (min-width: 748px) {
        .article-preview.uncropped .lead-in {
          font-size: 18px;
          line-height: 26px;
          max-width: 75%; } }
      @media only screen and (min-width: 1024px) {
        .article-preview.uncropped .lead-in {
          font-size: 18px;
          line-height: 26px; } }
  .article-preview.featured .vertical-align .title {
    margin: 0; }
  @media only screen and (min-width: 748px) {
    .article-preview.featured .post-categories a {
      color: #fff;
      font-size: 18px; } }
  .article-preview.normal {
    margin-bottom: 42px; }
    .article-preview.normal .post-categories {
      color: #0042FC; }
    .article-preview.normal .title {
      font-family: "Optima W01", sans-serif;
      font-size: 22px;
      letter-spacing: 0;
      color: #000;
      text-transform: capitalize;
      text-align: center;
      margin: 0; }
      @media only screen and (min-width: 748px) {
        .article-preview.normal .title {
          font-size: 28px;
          line-height: 32px; } }
  .article-preview .vertical-align {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    max-width: 760px;
    margin: 0 auto; }
    .article-preview .vertical-align .title {
      margin: 0 0 20px 0;
      color: #fff;
      font-size: 38px;
      line-height: 44px; }
      @media only screen and (min-width: 748px) {
        .article-preview .vertical-align .title {
          font-size: 38px;
          line-height: 44px; } }
      @media only screen and (min-width: 1024px) {
        .article-preview .vertical-align .title {
          font-size: 78px;
          line-height: 84px; } }
    .article-preview .vertical-align .lead-in {
      font-family: "Graphik", sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0px;
      color: #fff;
      text-transform: none;
      margin: 0 auto; }
      @media only screen and (min-width: 748px) {
        .article-preview .vertical-align .lead-in {
          font-size: 18px;
          line-height: 26px; } }
      @media only screen and (min-width: 1024px) {
        .article-preview .vertical-align .lead-in {
          font-size: 18px;
          line-height: 26px; } }

.previews .row {
  font-size: 0;
  text-align: center; }
  .previews .row > div {
    padding: 0 20px;
    margin-bottom: 30px; }
  @media only screen and (max-width: 748px) {
    .previews .row .overlay {
      display: none; }
    .previews .row .vertical-align {
      position: static;
      transform: none;
      padding-top: 20px; }
      .previews .row .vertical-align .post-categories {
        color: #0042FC; }
      .previews .row .vertical-align .title {
        color: #000; } }
  @media only screen and (min-width: 748px) {
    .previews .row > div {
      padding: 0 30px; }
    .previews .row .article-preview {
      display: inline-block;
      width: 50%; } }

.intro-wrapper {
  position: relative;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  text-align: center; }
  .intro-wrapper.visible {
    opacity: 1; }
  .intro-wrapper .table-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  @media only screen and (max-width: 748px) {
    .intro-wrapper .display-table, .intro-wrapper .intro-links {
      display: none; } }
  .intro-wrapper .home-intro {
    display: inline-block;
    position: relative; }
    .intro-wrapper .home-intro a {
      display: block; }
    .intro-wrapper .home-intro .overlay-image-wrap {
      position: relative; }
      .intro-wrapper .home-intro .overlay-image-wrap img {
        display: block;
        transition: filter .2s ease-in;
        max-width: 100%; }
    .intro-wrapper .home-intro .hover-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: background-color .2s ease-in-out; }
    .intro-wrapper .home-intro:hover {
      cursor: pointer; }
      .intro-wrapper .home-intro:hover .hover-overlay img {
        filter: sepia(1) hue-rotate(200deg); }
    .intro-wrapper .home-intro .title {
      display: inline-block;
      color: white;
      font-family: "Optima W01", sans-serif; }
      @media only screen and (min-width: 748px) {
        .intro-wrapper .home-intro .title {
          font-size: 78px;
          line-height: 84px; } }
    .intro-wrapper .home-intro .post-categories a {
      color: white;
      font-size: 12px;
      line-height: 16px; }
      @media only screen and (min-width: 1024px) {
        .intro-wrapper .home-intro .post-categories a {
          font-size: 18px; } }
    .intro-wrapper .home-intro .fixed-dm {
      position: absolute;
      left: -80px;
      top: 50%;
      transform: rotate(-90deg);
      font-size: 18px;
      font-family: "Graphik", sans-serif;
      font-weight: 600;
      color: #0042FC;
      letter-spacing: .5em; }
    .intro-wrapper .home-intro .fixed-year {
      position: absolute;
      right: -80px;
      top: 50%;
      transform: rotate(-90deg);
      font-size: 18px;
      font-family: "Graphik", sans-serif;
      font-weight: 600;
      color: #0042FC;
      letter-spacing: .5em; }
    .intro-wrapper .home-intro .fixed-mag {
      width: 100%;
      position: absolute;
      bottom: -15px;
      text-transform: uppercase;
      font-size: 18px;
      font-family: "Graphik", sans-serif;
      font-weight: 600;
      color: #0042FC;
      letter-spacing: 1em; }
  .intro-wrapper .rav-logo {
    text-align: center;
    width: 100%;
    position: absolute;
    padding: 20px 80px 0 80px;
    z-index: 1; }
    @media only screen and (max-width: 748px) {
      .intro-wrapper .rav-logo {
        position: static;
        padding: 20px; } }
    .intro-wrapper .rav-logo svg {
      fill: #0042FC;
      max-width: 1200px; }
      .intro-wrapper .rav-logo svg.scrolled {
        fill: white; }
  .intro-wrapper .intro-links {
    width: 100%;
    position: absolute;
    bottom: 70px; }
    .intro-wrapper .intro-links a {
      font-size: 14px;
      cursor: pointer; }
    .intro-wrapper .intro-links a.intro-subscribe {
      position: absolute;
      left: 40px; }
    .intro-wrapper .intro-links a.intro-contact {
      position: absolute;
      right: 40px; }
    .intro-wrapper .intro-links .icon-line-thin {
      transition: color .2s ease-in-out, background-color .2s ease-in-out;
      color: #0042FC;
      background-color: #0042FC; }
    .intro-wrapper .intro-links:hover {
      color: black; }
      .intro-wrapper .intro-links:hover .icon-line-thin {
        color: black;
        background-color: black; }

footer {
  transition: opacity .25s ease-in;
  color: #fff; }
  footer a, footer i, footer button {
    color: #fff; }
  footer a {
    font-family: "Optima W01", sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    color: #fff;
    text-transform: capitalize; }
    @media only screen and (min-width: 748px) {
      footer a {
        font-size: 28px; } }
  footer .icon-line-thin {
    background-color: #fff;
    color: #fff; }
  footer .copy {
    font-family: "Graphik", sans-serif;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .100rem;
    color: #fff;
    text-transform: uppercase; }

footer {
  padding: 50px 0 0; }
  footer .row {
    background-color: #0042FC; }
  footer .row.table-cells {
    display: table;
    width: 100%;
    background-color: transparent; }
    @media only screen and (max-width: 748px) {
      footer .row.table-cells {
        display: block; } }
  footer .footer-left {
    width: 75%;
    display: table-cell;
    vertical-align: middle;
    background-color: #0042FC;
    border-right: 10px solid white; }
    footer .footer-left ul li {
      margin: 20px 0; }
    footer .footer-left ul li:last-child {
      margin-bottom: 0; }
    footer .footer-left nav {
      margin: 0; }
    @media only screen and (max-width: 748px) {
      footer .footer-left {
        width: 100%;
        display: block;
        border-right: none;
        margin-bottom: 10px;
        padding: 30px 0; }
        footer .footer-left li:first-child {
          margin-top: 0; } }
  footer .footer-right {
    width: 25%;
    min-width: 266px;
    vertical-align: middle;
    display: table-cell;
    text-align: center;
    padding: 30px 0;
    background-color: #0042FC; }
    footer .footer-right span {
      font-size: 10px;
      letter-spacing: .1em;
      display: block;
      text-transform: uppercase; }
    footer .footer-right img {
      padding: 60px 0;
      max-width: 90px; }
    footer .footer-right .copy {
      font-size: 10px;
      letter-spacing: .1em;
      text-transform: none;
      line-height: 1.6em;
      font-weight: normal; }
    @media only screen and (max-width: 748px) {
      footer .footer-right {
        width: 100%;
        display: block; } }
  footer nav {
    margin: 0 auto 20px; }
    footer nav ul {
      display: inline-block; }
      footer nav ul li a {
        padding: 0 27.5px; }
  footer .copy {
    margin-bottom: 70px;
    margin: 0 auto;
    padding: 0 20px; }
  @media only screen and (min-width: 748px) {
    footer {
      padding: 50px 0 0px;
      margin: 0 10px 10px; }
      footer nav {
        margin: 55px auto; }
      footer .copy {
        max-width: 80%;
        padding: 0; } }
  footer .footer-email-form {
    max-width: none;
    background-image: url("../images/pattern.png");
    margin-bottom: 10px;
    padding: 35px 0; }
    footer .footer-email-form span {
      display: inline-block;
      vertical-align: top;
      font-family: "Optima W01", sans-serif;
      font-size: 28px;
      line-height: 65px;
      padding-right: 25px; }
      @media only screen and (max-width: 875px) {
        footer .footer-email-form span {
          display: block;
          text-align: center;
          line-height: 1em;
          padding-bottom: 20px; } }
    footer .footer-email-form input {
      background: #0042FC;
      display: inline-block;
      vertical-align: top;
      width: 100%;
      max-width: 360px;
      color: white;
      height: 65px;
      border: 4px solid white;
      padding-left: 20px;
      text-transform: none;
      font-size: 14px;
      letter-spacing: .1em;
      transition: color .3s ease-in-out;
      margin-right: 25px; }
      footer .footer-email-form input::-webkit-input-placeholder {
        color: white; }
      footer .footer-email-form input:-moz-placeholder {
        /* Firefox 18- */
        color: white; }
      footer .footer-email-form input::-moz-placeholder {
        /* Firefox 19+ */
        color: white; }
      footer .footer-email-form input:-ms-input-placeholder {
        color: white; }
      footer .footer-email-form input:focus::-webkit-input-placeholder {
        color: transparent; }
      footer .footer-email-form input:focus:-moz-placeholder {
        /* Firefox 18- */
        color: transparent; }
      footer .footer-email-form input:focus::-moz-placeholder {
        /* Firefox 19+ */
        color: transparent; }
      footer .footer-email-form input:focus:-ms-input-placeholder {
        color: transparent; }
      @media only screen and (max-width: 748px) {
        footer .footer-email-form input {
          max-width: none;
          width: 90%;
          margin: 0 auto 20px; } }
    footer .footer-email-form button {
      cursor: pointer;
      border: none;
      height: 65px;
      width: 120px;
      display: inline-block;
      vertical-align: top;
      background: white;
      color: #0042FC;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: .1em; }
      @media only screen and (max-width: 748px) {
        footer .footer-email-form button {
          width: 90%; } }

:focus {
  outline-color: transparent;
  border-color: transparent;
  outline: 0; }

form.search input[type="text"] {
  font-family: "Optima W01", sans-serif;
  font-size: 60px;
  letter-spacing: 0rem;
  color: #000;
  text-transform: lowercase; }

form.email label {
  font-family: "Optima W01", sans-serif;
  font-size: 28px;
  color: #000;
  letter-spacing: 0;
  text-transform: none; }

form.email input[type="email"] {
  font-family: "Graphik", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: .1rem;
  color: #000;
  text-transform: uppercase; }

@font-face {
  font-family: "ravelin";
  src: url("../fonts/ravelin.eot");
  src: url("../fonts/ravelin.eot?#iefix") format("embedded-opentype"), url("../fonts/ravelin.woff") format("woff"), url("../fonts/ravelin.ttf") format("truetype"), url("../fonts/ravelin.svg#ravelin") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "ravelin" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "ravelin" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  font-smoothing: antialiased; }

.icon-apple:before {
  content: "a"; }

.icon-arrow-down:before {
  content: "d"; }

.icon-arrow-right:before, .icon-arrow-left:before {
  content: "r"; }

.icon-arrow-left:before {
  transform: rotate(180deg);
  display: inline-block; }

.icon-menu:before {
  content: "m"; }

.icon-x-thick:before {
  content: "x"; }

.icon-no:before {
  content: "n"; }

.icon-search:before {
  content: "s"; }

.icon-tape:before {
  content: "p"; }

.icon-twitter:before {
  content: "t"; }

.icon-instagram:before {
  content: "i"; }

.icon-facebook:before {
  content: "f"; }

.icon-pinterest:before {
  content: "b"; }

.icon-tumblr:before {
  content: "e"; }

.icon-x-thin:before {
  content: "y"; }

.icon-left-edge:before {
  content: "c"; }

.icon-right-edge:before {
  content: "g"; }

.icon-line, .icon-line-thin {
  width: 100%;
  position: relative;
  display: block;
  height: 6px;
  font-size: 6px; }
  .icon-line:before, .icon-line:after, .icon-line-thin:before, .icon-line-thin:after {
    font-family: "ravelin" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    font-smoothing: antialiased;
    position: absolute;
    transform: scale(1.11);
    display: block;
    height: 6px; }
  .icon-line:before, .icon-line-thin:before {
    content: "c";
    left: -2px; }
  .icon-line:after, .icon-line-thin:after {
    content: "g";
    right: -6px; }

.icon-line-thin {
  height: 3px;
  font-size: 3px; }
  .icon-line-thin:before, .icon-line-thin:after {
    transform: translateY(0.1px) scale(1.05); }
  .icon-line-thin:before {
    left: -2px; }
  .icon-line-thin:after {
    right: -3px; }

.icon-tape-box {
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 1px soid #fff;
  transform: rotate(45deg);
  background-color: #0042FC; }
  .icon-tape-box:before {
    content: "p";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-45deg) translateY(-70%); }

.navigation .frame {
  position: fixed;
  z-index: 3; }
  .navigation .frame.frame-top {
    top: 0;
    left: 0;
    right: 0;
    height: 58px; }
    .navigation .frame.frame-top svg {
      margin-top: 15px;
      display: inline-block;
      height: 1.725rem;
      transition: fill .3s ease-in-out; }
      @media only screen and (min-width: 748px) {
        .navigation .frame.frame-top svg {
          margin-top: 16px;
          display: inline-block;
          height: 1.125rem; } }
      .home .navigation .frame.frame-top svg {
        fill: transparent; }
    .home .navigation .frame.frame-top a.scrolled svg {
      fill: white; }
    .navigation .frame.frame-top:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 58px;
      height: 10px;
      background: white; }
      @media only screen and (min-width: 748px) {
        .navigation .frame.frame-top:after {
          left: 50px;
          right: 50px;
          top: 50px; } }
  .navigation .frame.frame-bottom {
    left: 0;
    right: 0;
    bottom: -50px;
    height: 50px; }
    .navigation .frame.frame-bottom ul {
      padding: 17px 0; }
      .navigation .frame.frame-bottom ul li {
        margin-left: 35px; }
        .navigation .frame.frame-bottom ul li:first-child {
          margin-left: 0; }
    @media only screen and (min-width: 748px) {
      .navigation .frame.frame-bottom:before {
        content: '';
        position: absolute;
        left: 50px;
        right: 50px;
        top: -10px;
        height: 10px;
        background: white; } }
  .navigation .frame.frame-left {
    top: 0;
    left: 0;
    width: 58px;
    height: 58px; }
    .navigation .frame.frame-left:after {
      content: '';
      position: absolute;
      left: 50px;
      top: 50px;
      bottom: 50px;
      background: white;
      width: 10px; }
  .navigation .frame.frame-right {
    top: 0;
    right: 0;
    width: 58px;
    height: 58px; }
    .navigation .frame.frame-right:after {
      content: '';
      position: absolute;
      right: 50px;
      top: 50px;
      bottom: 50px;
      background: white;
      width: 10px; }
  @media only screen and (min-width: 748px) {
    .navigation .frame.frame-top {
      height: 50px; }
    .navigation .frame.frame-left, .navigation .frame.frame-right {
      width: 50px;
      height: auto;
      bottom: 0; }
    .navigation .frame.frame-bottom {
      bottom: 0; } }
  .navigation .frame .panel-toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .navigation .frame .panel-toggle i {
      padding: 0 20px;
      width: 58px;
      height: 58px; }
      @media only screen and (min-width: 748px) {
        .navigation .frame .panel-toggle i {
          padding: 0 14px;
          width: 50px;
          height: 50px; } }

.navigation .panel {
  z-index: 2;
  width: 100vw;
  top: 50px;
  bottom: 0;
  position: fixed;
  padding: 30px 0; }
  @media only screen and (min-width: 748px) {
    .navigation .panel {
      padding: 46px 0;
      width: 50vw;
      bottom: 50px; } }
  .navigation .panel.menu {
    left: -100vw;
    overflow-y: auto; }
    @media only screen and (min-width: 748px) {
      .navigation .panel.menu {
        padding-left: 50px; } }
    .navigation .panel.menu ul.categories li a {
      display: block;
      padding-bottom: 20px;
      transition: color .2s ease-in-out; }
      @media only screen and (min-width: 748px) {
        .navigation .panel.menu ul.categories li a {
          padding-bottom: 13px; } }
      .navigation .panel.menu ul.categories li a:hover {
        color: black; }
    .navigation .panel.menu ul.categories li:last-child a {
      padding-bottom: 0 !important; }
    .navigation .panel.menu ul.pages li a {
      display: inline-block;
      padding: 0 18px;
      transition: color .2s ease-in-out; }
      .navigation .panel.menu ul.pages li a:hover {
        color: black; }
    .navigation .panel.menu ul.social {
      margin-top: 20px; }
      @media only screen and (min-width: 748px) {
        .navigation .panel.menu ul.social {
          display: none; } }
      .navigation .panel.menu ul.social i {
        padding: 0 20px; }
    .navigation .panel.menu .icon-line-thin {
      margin: 35px auto; }
      @media only screen and (min-width: 748px) {
        .navigation .panel.menu .icon-line-thin.show-mobile {
          display: none; } }
  .navigation .panel.search {
    right: -100vw; }
    .navigation .panel.search .search-form {
      position: absolute;
      left: 20px;
      right: 20px;
      top: 50%;
      transform: translateY(-50%); }
      .navigation .panel.search .search-form input {
        display: block;
        width: 100%;
        padding-right: 60px;
        margin-left: -5px; }
      .navigation .panel.search .search-form button {
        right: 0;
        top: 15px; }
    @media only screen and (min-width: 748px) {
      .navigation .panel.search {
        padding-right: 50px; }
        .navigation .panel.search .search-form {
          left: 44px;
          right: 90px; }
          .navigation .panel.search .search-form button {
            top: 13px;
            right: -12px; } }

.navigation svg, .navigation i {
  fill: #fff;
  color: #fff; }
  .navigation svg:hover, .navigation i:hover {
    fill: #000;
    color: #000; }

.navigation.dark-ui svg, .navigation.dark-ui i {
  fill: #000;
  color: #000; }

.navigation .frame {
  background-color: #0042FC; }

.navigation .panel-toggle {
  display: block;
  width: 100%;
  text-align: center; }
  .navigation .panel-toggle i:before {
    font-size: 1.4rem; }
    @media only screen and (min-width: 748px) {
      .navigation .panel-toggle i:before {
        font-size: 1.3rem; } }

.navigation .panel {
  text-align: center;
  background-color: #fff; }

.navigation ul {
  list-style: none; }

.navigation nav ul {
  list-style: none; }
  .navigation nav ul.categories li a {
    font-family: "Optima W01", sans-serif;
    letter-spacing: 0rem;
    color: #0042FC;
    text-transform: capitalize;
    font-size: 26px;
    font-weight: normal; }
    @media only screen and (min-width: 748px) {
      .navigation nav ul.categories li a {
        font-size: 42px;
        line-height: 42px; } }
  .navigation nav ul.pages li a {
    font-family: "Graphik", sans-serif;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: #0042FC; }
  .navigation nav ul.social i {
    color: #0042FC; }

.navigation .slide-in-left .icon-line-thin {
  color: #0042FC;
  background-color: #0042FC;
  max-width: 25px;
  margin-bottom: 25px; }

.burger-menu {
  display: block;
  width: 21px;
  height: 16px;
  margin: 0 auto; }
  .burger-menu span {
    background: white;
    color: white;
    width: 100%;
    height: 3px;
    padding: 0;
    margin: 0;
    display: block;
    transition: all .3s ease-in-out; }
    .dark-ui .burger-menu span {
      color: black;
      background-color: black; }
    .burger-menu span.mid {
      margin: 4px 0; }
  .menu-open .burger-menu span.top {
    transform: rotate(45deg) translate(2px, 6px); }
  .menu-open .burger-menu span.mid {
    color: transparent;
    background-color: transparent; }
  .menu-open .burger-menu span.bottom {
    transform: rotate(-45deg) translate(4px, -8px); }

.navigation .frame {
  transition: background-color .35s ease-in, margin .25s ease-in; }
  @media only screen and (min-width: 748px) {
    .video-playing.video-visible .navigation .frame {
      background-color: #000 !important; }
      .video-playing.video-visible .navigation .frame.frame-top {
        margin-top: -50px; }
      .video-playing.video-visible .navigation .frame.frame-right {
        margin-right: -50px; }
      .video-playing.video-visible .navigation .frame.frame-bottom {
        margin-bottom: -50px; }
      .video-playing.video-visible .navigation .frame.frame-left {
        margin-left: -50px; } }

.navigation .panel {
  transition: left .35s ease-in, right .35s ease-in, opacity .4s ease-in;
  opacity: .8; }

.menu-open .navigation .panel.menu {
  left: 0;
  opacity: 1; }

.menu-open .navigation .icon-menu {
  animation-name: flip; }
  .menu-open .navigation .icon-menu:before {
    content: 'y'; }

.search-open .navigation .panel.search {
  right: 0;
  opacity: 1; }

.search-open .navigation .frame .icon-search {
  animation-name: flip;
  animation-direction: normal; }
  .search-open .navigation .frame .icon-search:before {
    content: 'y'; }

.content {
  transition: all .2s ease-in;
  backface-visibility: hidden; }

.search-open, .menu-open {
  overflow: hidden; }
  .search-open .content, .menu-open .content {
    opacity: .5; }

.search-form input[type="text"] {
  font-family: 'Optima';
  font-size: 60px;
  letter-spacing: 0;
  color: #000;
  text-transform: lowercase;
  border: none; }
  .search-form input[type="text"]::-moz-placeholder {
    color: #000; }
  .search-form input[type="text"]:-ms-input-placeholder {
    color: #000; }
  .search-form input[type="text"]::-webkit-input-placeholder {
    color: #000; }

.search-form .icon-line {
  background-color: #0042FC;
  color: #0042FC; }

.search-form button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0; }
  .search-form button i {
    color: #0042FC;
    font-size: 2.5rem; }
    @media only screen and (min-width: 748px) {
      .search-form button i {
        font-size: 3rem; } }

.search-form svg {
  fill: #0042FC; }

.search-header .filter {
  font-family: 'Optima';
  font-size: 26px;
  letter-spacing: 0;
  color: #0042FC;
  text-transform: capitalize; }

.search-header .search-query {
  color: #000;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 265px; }
  .search-header .search-query .icon-line-thin {
    color: #0042FC;
    background-color: #0042FC;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }

@media only screen and (min-width: 748px) {
  .search-header .search-query .icon-line-thin {
    display: inline-block; }
  .search-header br {
    display: none; } }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }
  @media only screen and (max-width: 748px) {
    ul.inline li {
      margin-bottom: 20px; } }
  @media only screen and (min-width: 748px) {
    ul.inline li {
      display: inline-block;
      vertical-align: top; } }
  ul.always-inline li {
    display: inline-block;
    vertical-align: top; }

.email-form {
  padding: 0 20px;
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  position: relative; }
  .email-form input {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 14px;
    font-family: "Graphik", sans-serif;
    font-weight: 600;
    letter-spacing: .1rem;
    text-transform: uppercase;
    width: 100%;
    padding-right: 20px; }
    .email-form input::-moz-placeholder {
      color: #fff; }
    .email-form input:-ms-input-placeholder {
      color: #fff; }
    .email-form input::-webkit-input-placeholder {
      color: #fff; }
  .email-form button {
    position: absolute;
    width: 30px;
    font-size: 20px;
    border: none;
    background-color: transparent;
    text-align: right;
    right: 20px;
    top: 0;
    z-index: 2;
    cursor: pointer; }
  .email-form i.icon-line-thin {
    margin-top: 10px; }

.email-block {
  position: fixed;
  background-color: #fff;
  left: 0;
  right: 0;
  bottom: -100%;
  padding: 40px 20px 50px;
  transition: bottom .25s ease-in; }
  .email-block.success {
    transition: bottom .25s ease-in 3s; }
  @media only screen and (min-width: 748px) {
    .email-block {
      padding: 30px 60px;
      left: 60px;
      right: 60px; } }
  .email-block p {
    font-family: "Optima W01", sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    color: #000;
    text-align: center;
    display: block;
    margin: 10px auto 40px;
    max-width: 800px; }
  .email-block a[href="#close"] {
    color: #000;
    position: absolute;
    right: 20px;
    top: 20px; }
    @media only screen and (min-width: 748px) {
      .email-block a[href="#close"] {
        right: 70px; } }
  .email-block .email-form {
    color: #0042FC; }
    .email-block .email-form .icon-line-thin {
      color: #0042FC;
      background-color: #0042FC; }
    .email-block .email-form input {
      color: black; }
      .email-block .email-form input::-moz-placeholder {
        color: #000; }
      .email-block .email-form input:-ms-input-placeholder {
        color: #000; }
      .email-block .email-form input::-webkit-input-placeholder {
        color: #000; }
    .email-block .email-form button {
      cursor: pointer; }
      .email-block .email-form button:hover {
        cursor: pointer; }
  .email-visible .email-block {
    transition: bottom .25s ease-in;
    bottom: 0; }
    @media only screen and (min-width: 748px) {
      .email-visible .email-block {
        bottom: 60px; } }

.filter {
  text-align: center;
  margin-bottom: 35px; }
  @media only screen and (min-width: 1024px) {
    .filter {
      margin-bottom: 65px; } }
  .filter .select {
    position: relative;
    display: inline-block;
    min-width: 200px; }
    .filter .select select {
      width: 100%;
      display: inline-block;
      appearance: none;
      border: 2px solid #0042FC;
      padding: 12px;
      border-radius: 0;
      font-family: "Optima W01", sans-serif;
      font-size: 24px;
      letter-spacing: 0;
      text-transform: capitalize;
      color: #0042FC;
      background-color: transparent; }
    .filter .select:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #0042FC;
      margin: -2.5px 0 0 5px;
      position: absolute;
      right: 20px;
      top: 50%; }
  .filter .inline {
    display: none; }
  .filter li {
    padding-top: 20px;
    height: 40px; }
  .filter a {
    font-family: "Optima W01", sans-serif;
    font-size: 24px;
    letter-spacing: 0;
    color: #0042FC;
    text-transform: capitalize;
    line-height: 0;
    transition: color .2s ease-in-out; }
    .filter a .icon-line-thin {
      transition: color .2s ease-in-out, background-color .2s ease-in-out;
      color: #0042FC;
      background-color: #0042FC; }
    .filter a:hover {
      color: black; }
      .filter a:hover .icon-line-thin {
        color: black;
        background-color: black; }
  @media only screen and (min-width: 748px) {
    .filter .select {
      display: none; }
    .filter .inline {
      display: block; }
    .filter li {
      margin: 0 40px; }
    .filter a {
      font-size: 42px; }
    .filter.search li {
      padding-top: 13px;
      height: 46px; }
    .filter.search a {
      font-size: 26px; } }
  .filter li.active a {
    font-weight: bold; }

.lightbox {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 300;
  top: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #0042FC;
  opacity: 0;
  transition: background-color .2s ease-in, opacity .3s ease-in, top .0s linear .3s; }
  .lightbox.slick-slider {
    display: block; }
  .lightbox .close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 2.3rem;
    z-index: 305; }
  .lightbox .slick-arrow {
    display: inline-block;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    font-size: 3rem;
    z-index: 301; }
    .lightbox .slick-arrow.slick-prev {
      right: auto;
      left: 30px;
      transform: translateY(-50%) rotate(180deg); }
  @media only screen and (max-width: 748px) {
    .lightbox button {
      display: none !important; } }
  .lightbox a, .lightbox button {
    cursor: pointer; }
  .lightbox a, .lightbox button, .lightbox .caption {
    transition: color .2s ease-in;
    color: #fff; }
    .lightbox a:hover, .lightbox button:hover, .lightbox .caption:hover {
      color: #000; }
  .lightbox.dark-ui a, .lightbox.dark-ui button, .lightbox.dark-ui .caption {
    color: #000; }
    .lightbox.dark-ui a:hover, .lightbox.dark-ui button:hover, .lightbox.dark-ui .caption:hover {
      color: #fff; }
  .lightbox .item {
    min-height: 100vh;
    position: relative; }
    .lightbox .item img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      padding: 60px; }
    .lightbox .item .caption {
      position: absolute;
      bottom: 0;
      line-height: 1;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Graphik", sans-serif;
      font-size: 12px;
      letter-spacing: .1rem;
      display: block;
      height: 25px; }
      .lightbox .item .caption b, .lightbox .item .caption strong {
        font-weight: 600; }
  .lightbox-active .lightbox {
    top: 0;
    opacity: 1;
    transition: background-color .2s ease-in, opacity .3s ease-in; }

.content [data-ravelin-visible] {
  transition: opacity .1s ease-in, transform .1s ease-in, filter .1s ease-in;
  opacity: 0;
  backface-visibility: hidden; }

.content [data-ravelin-visible="scrolled"] {
  opacity: 1; }

.content i.icon-load-more {
  color: #0042FC;
  margin: 0 auto;
  display: none;
  text-align: center;
  font-size: 3rem; }
  .loading .content i.icon-load-more {
    display: block;
    animation-name: flash; }

.home footer {
  float: left;
  width: 100%;
  clear: both; }

.home-grid {
  max-width: 1280px;
  margin: 0 auto; }
  .home-grid.page {
    padding-top: 2em; }
  .home-grid:after {
    content: "";
    display: table;
    clear: both; }
  @media only screen and (max-width: 748px) {
    .home-grid {
      padding: 0 20px; } }
  .home-grid .mobile-featured {
    display: none; }
    @media only screen and (max-width: 748px) {
      .home-grid .mobile-featured {
        display: block; } }
  .home-grid .article-preview img {
    opacity: 1;
    transition: opacity .3s ease-in-out; }
  .home-grid .article-preview .title {
    font-size: 38px;
    line-height: 42px; }
  .home-grid .article-preview .post-categories {
    margin: 14px 0 7px; }
  .home-grid .article-preview.featured .post-categories {
    font-size: 18px;
    margin: 10px 0; }
  .home-grid .row {
    float: left;
    clear: both;
    width: 100%;
    margin-bottom: 50px; }
    .home-grid .row > div {
      padding: 0 0px;
      margin-bottom: 0px; }
    .home-grid .row .article-preview {
      max-width: 100%;
      vertical-align: top; }
    .home-grid .row.a .featured {
      width: 571px;
      margin: 0 auto 50px;
      display: block; }
      .home-grid .row.a .featured img {
        max-width: 100%;
        margin: 0 auto; }
      @media only screen and (min-width: 1200px) {
        .home-grid .row.a .featured {
          width: 571px;
          float: left;
          clear: both; } }
    .home-grid .row.a .normal {
      margin: 0 auto;
      display: block; }
      .home-grid .row.a .normal .overlay-wrapper {
        max-width: 428px;
        margin: 0 auto; }
      @media only screen and (min-width: 1200px) {
        .home-grid .row.a .normal {
          width: 428px;
          margin-top: 15%;
          float: right;
          margin-right: 2%; } }
    .home-grid .row.b .normal.tall {
      margin: 0 auto 50px;
      display: block; }
      .home-grid .row.b .normal.tall .overlay-wrapper {
        max-width: 255px;
        margin: 0 auto; }
      @media only screen and (min-width: 1050px) {
        .home-grid .row.b .normal.tall {
          width: 255px;
          margin-top: 43%;
          margin-left: 3%;
          float: left;
          clear: both; } }
    .home-grid .row.b .featured {
      margin: 0 auto;
      display: block; }
      .home-grid .row.b .featured img {
        width: 574px;
        margin: 0 auto; }
      .home-grid .row.b .featured p {
        max-width: 574px; }
      @media only screen and (min-width: 1050px) {
        .home-grid .row.b .featured {
          width: 574px;
          margin-right: 3%;
          float: right; } }
    .home-grid .row.c .featured {
      float: left;
      clear: both;
      width: 100%; }
      .home-grid .row.c .featured .overlay-wrapper {
        margin: 0 auto;
        display: block;
        max-width: 828px;
        width: 100%; }
      .home-grid .row.c .featured p {
        max-width: 828px; }
    .home-grid .row.d .article-preview {
      margin: 0 auto;
      display: block; }
      @media only screen and (min-width: 1128px) {
        .home-grid .row.d .article-preview {
          width: 657px;
          float: right; } }
      .home-grid .row.d .article-preview.tall {
        margin: 0 auto 50px;
        display: block; }
        .home-grid .row.d .article-preview.tall img {
          width: 305px;
          margin: 0 auto; }
        @media only screen and (min-width: 1128px) {
          .home-grid .row.d .article-preview.tall {
            width: 305px;
            margin-top: 35%;
            margin-left: 3%;
            float: left;
            clear: both; } }
    .home-grid .row.e .normal {
      width: 476px;
      margin: 0 auto 50px;
      display: block; }
      @media only screen and (min-width: 1230px) {
        .home-grid .row.e .normal {
          float: left;
          clear: both;
          margin-top: 22.58%;
          margin-left: 3%; } }
    .home-grid .row.e .featured {
      width: 575px;
      margin: 0 auto;
      display: block; }
      @media only screen and (min-width: 1230px) {
        .home-grid .row.e .featured {
          float: right; } }
      .home-grid .row.e .featured p {
        max-width: 575px; }
    .home-grid .row.f .featured {
      width: 575px;
      margin: 0 auto 50px;
      display: block; }
      @media only screen and (min-width: 1050px) {
        .home-grid .row.f .featured {
          margin-left: 3%;
          float: left;
          clear: both; } }
      .home-grid .row.f .featured p {
        max-width: 575px; }
    .home-grid .row.f .normal {
      margin: 0 auto;
      display: block; }
      .home-grid .row.f .normal .overlay-wrapper {
        width: 272px;
        margin: 0 auto; }
      @media only screen and (min-width: 1050px) {
        .home-grid .row.f .normal {
          width: 272px;
          margin-top: 45%;
          margin-right: 3%;
          float: right; } }
    .home-grid .row.g .article-preview {
      margin: 0 auto 50px;
      display: block; }
      .home-grid .row.g .article-preview img {
        width: 305px;
        margin: 0 auto; }
      @media only screen and (min-width: 1128px) {
        .home-grid .row.g .article-preview {
          width: 305px;
          margin-top: 35%;
          margin-left: 3%;
          float: left;
          clear: both; } }
      .home-grid .row.g .article-preview.tall {
        margin: 0 auto;
        display: block; }
        @media only screen and (min-width: 1128px) {
          .home-grid .row.g .article-preview.tall {
            width: 657px;
            float: right; } }

.pagination {
  text-align: center; }
  .pagination a {
    font-size: 12px;
    background: #0042FC;
    display: inline-block;
    padding: 0px 10px;
    color: white; }
    .pagination a .invisible {
      display: none; }
    .pagination a.current {
      background-color: #f3f3f3;
      color: #0042FC; }
    .pagination a.prev, .pagination a.next {
      background-color: transparent;
      color: #0042FC; }

.previews.archive {
  font-size: 0; }
  @media only screen and (max-width: 748px) {
    .previews.archive {
      text-align: center; } }
  .previews.archive .no-results {
    margin-top: 20%; }
  .previews.archive .article-preview {
    text-align: center;
    display: inline-block;
    vertical-align: top; }
    @media only screen and (max-width: 748px) {
      .previews.archive .article-preview:first-child {
        margin-top: 0; } }
    @media only screen and (min-width: 748px) {
      .previews.archive .article-preview {
        width: 50%;
        padding: 0 15px; } }

.single-page .related-projects {
  padding-bottom: 60px; }

@media only screen and (min-width: 748px) {
  .single-page.playlists .external-links-block {
    margin: 20px 0 50px; }
  .single-page.playlists .post-information, .single-page.playlists .callout, .single-page.playlists .text-block, .single-page.playlists .external-links-block, .single-page.playlists .share-block {
    margin-left: 0%;
    padding-left: 0px;
    width: 100%; }
  .single-page.playlists .playlist-block {
    padding-left: 30px;
    width: 600px; }
  .single-page.playlists .playlist-block iframe {
    padding-right: 60px; }
  .single-page.playlists .article-content {
    width: 50%;
    margin-left: 50%; } }

.single-page .post-information {
  margin-bottom: 20px; }
  .single-page .post-information p {
    margin: 0; }
  .single-page .post-information span {
    display: block; }
  @media only screen and (min-width: 748px) {
    .single-page .post-information {
      max-width: 300px; } }

.single-page .callout {
  margin: 40px 0 0;
  font-size: 20px;
  width: 50%;
  font-size: 24px;
  line-height: 32px;
  margin-left: 25.5%; }
  @media only screen and (max-width: 1200px) {
    .single-page .callout {
      width: 62.5%; } }
  @media only screen and (max-width: 747px) {
    .single-page .callout {
      width: 100%;
      margin-left: 0; } }
  .single-page .callout p {
    margin-bottom: 0; }

.single-page .share-block {
  margin-bottom: 50px; }

.single-page .external-links-block, .single-page .share-block {
  display: block; }
  @media only screen and (min-width: 748px) {
    .single-page .external-links-block, .single-page .share-block {
      vertical-align: top;
      display: inline-block; } }
  .single-page .external-links-block p, .single-page .share-block p {
    margin-top: 40px;
    font-family: "Graphik", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    color: #000;
    text-transform: uppercase; }
    @media only screen and (min-width: 748px) {
      .single-page .external-links-block p, .single-page .share-block p {
        margin-top: 5px; } }
  .single-page .external-links-block .always-inline i, .single-page .share-block .always-inline i {
    padding: 15px 15px 15px 0; }

.single-page .long-credits {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 50px;
  width: 67.5%; }
  .single-page .long-credits h5 {
    margin-bottom: 10px; }
  @media only screen and (max-width: 1200px) {
    .single-page .long-credits {
      width: 84.375%; } }
  @media only screen and (max-width: 747px) {
    .single-page .long-credits {
      width: 100%; } }

.single-page .share-block {
  max-width: 74%;
  display: inline-block; }
  .single-page .share-block li {
    margin: 0 5px; }
    .single-page .share-block li a {
      font-size: 14px;
      color: #000;
      transition: color .2s ease-in; }
      .single-page .share-block li a:hover {
        color: #0042FC; }
    .single-page .share-block li:first-child {
      margin-left: 0; }
    .single-page .share-block li:last-child {
      margin-right: 0; }
  .single-page .share-block span {
    display: none !important; }

.single-page .external-links-block {
  width: 25%; }

.single-page .external-links-block a {
  font-family: "Graphik", sans-serif;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: .1rem;
  color: #0042FC;
  text-transform: lowercase;
  border-bottom: 1px solid #0042FC;
  transition: color .2s ease-in-out, border-color .2s ease-in-out; }
  .single-page .external-links-block a:hover {
    color: black;
    border-color: black; }

.single-page .tags a {
  color: #0042FC; }

.single-page .related-projects {
  background: white;
  margin-top: 85px;
  border-top: 6px solid #000; }
  .single-page .related-projects .module-header {
    padding: 40px 0 40px; }

.single-page .image-grid-wrapper {
  padding: 0 5px; }
  @media only screen and (min-width: 748px) {
    .single-page .image-grid-wrapper {
      padding: 0 15px; } }
  .single-page .image-grid-wrapper img {
    cursor: pointer; }

.app-edition {
  margin: 90px auto 0;
  padding: 0 20px; }
  .page-copy + .app-edition {
    margin-top: 30px; }
  .app-edition:last-child {
    margin-bottom: 50px; }
  @media only screen and (min-width: 748px) {
    .app-edition {
      width: 75%;
      padding: 30px; }
      .app-edition.offset-left {
        margin-left: 0; }
      .app-edition.offset-right {
        margin-left: 25%; }
      .app-edition .details {
        margin-left: 12.5%; } }
  .app-edition img {
    max-width: 100%; }
  .app-edition h2 {
    margin: 40px 0; }
    @media only screen and (min-width: 748px) {
      .app-edition h2 {
        margin: 50px 0; } }
  .app-edition h2, .app-edition h2 i {
    font-weight: normal;
    font-family: "Optima W01", sans-serif;
    font-size: 42px;
    letter-spacing: 0;
    color: #000;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: bottom; }
  .app-edition h2 i {
    margin-bottom: -7px; }
  .app-edition p {
    display: block;
    margin: 0;
    line-height: 1.5; }
  .app-edition .module-header {
    margin: 50px 0 15px; }
  .app-edition .cta {
    margin-top: 45px;
    font-family: "Graphik", sans-serif;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .1rem;
    text-transform: uppercase;
    color: #0042FC;
    display: inline-block;
    vertical-align: middle; }
    .app-edition .cta:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid #0042FC;
      margin: -2.5px 0 0 5px; }
    @media only screen and (min-width: 748px) {
      .app-edition .cta {
        margin-top: 50px; } }

.contact-newsletter-signup form i {
  color: #0042FC; }

.contact-newsletter-signup form input {
  color: #0042FC; }
  .contact-newsletter-signup form input::-moz-placeholder {
    color: #000; }
  .contact-newsletter-signup form input:-ms-input-placeholder {
    color: #000; }
  .contact-newsletter-signup form input::-webkit-input-placeholder {
    color: #000; }

.contact-newsletter-signup form .icon-line-thin {
  color: #0042FC;
  background-color: #0042FC; }

.search .search-header {
  margin-bottom: 42px; }

.about .content {
  padding-top: 75px; }
  .about .content .icon-line-thin {
    color: #0042FC;
    background-color: #0042FC;
    max-width: 25px;
    margin: 75px auto 0; }

.about .display-table {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%; }
  .about .display-table .table-cell {
    display: table-cell;
    vertical-align: middle; }

.about .about-block .image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 80vh;
  width: 50%;
  margin-right: -.25em;
  display: inline-block;
  vertical-align: top; }
  @media only screen and (max-width: 748px) {
    .about .about-block .image {
      display: none;
      padding-top: 75px;
      width: 100%;
      height: 30vh; } }

.about .about-block .about-content {
  width: 50%;
  margin-right: -.25em;
  display: inline-block;
  height: 80vh;
  vertical-align: top;
  font-family: "Optima W01", sans-serif; }
  @media only screen and (max-width: 748px) {
    .about .about-block .about-content {
      width: 100%;
      height: auto; } }
  .about .about-block .about-content .content-title {
    font-size: 48px;
    color: #0042fc; }
  .about .about-block .about-content h3 {
    margin-top: 75px;
    font-size: 28px;
    font-weight: normal;
    margin-bottom: 10px; }
  .about .about-block .about-content .member-position {
    margin-bottom: 10px; }
  .about .about-block .about-content a {
    color: black;
    margin: 0 5px; }

@media only screen and (max-width: 748px) {
  .about .about-block:last-child .image {
    display: block;
    margin-bottom: 75px; } }

.about .about-block:last-child {
  padding-bottom: 75px; }
  @media only screen and (max-width: 748px) {
    .about .about-block:last-child {
      padding-top: 75px; } }
  .about .about-block:last-child p {
    padding-top: 75px; }
